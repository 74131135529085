<template>
	<v-form ref="form" v-model="validationState">
		<v-layout column>
			<w-text-input
				v-model="bankAccount.bic"
				:label="$t('customer.bank.bic')"
				mask="AAAA AA NN NNN"
				maxlength="11"
				required
				:rules="[rules.bic]"
				validate-on-blur
			/>
			<w-text-input v-model="bankAccount.bank_name" :label="$t('customer.bank.bank_name')" maxlength="191" required validate-on-blur />
			<w-text-input v-model="bankAccount.bank_address" :label="$t('customer.bank.bank_address')" maxlength="191" required validate-on-blur />
			<w-text-input
				v-model="bankAccount.iban"
				:label="$t('customer.bank.iban')"
				maxlength="34"
				mask="AA## NNNN NNNN NNNN NNNN NNNN NNNN NNNN NN"
				required
				:rules="[rules.iban]"
				validate-on-blur
			/>
		</v-layout>
	</v-form>
</template>

<script>
import { BIC, IBAN } from 'ibankit'

export default {
	name: 'SimpleBankForm',
	props: {
		isValid: {
			required: true,
			type: Boolean
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			rules: {
				bic: value => {
					if (!value || !value.trim()) {
						return true
					}
					return BIC.isValid(value) || this.$t('customer.bank.errors.bic_not_valid')
				},
				iban: value => {
					if (!value || !value.trim()) {
						return true
					}
					return IBAN.isValid(value) || this.$t('customer.bank.errors.iban_not_valid')
				}
			}
		}
	},
	computed: {
		bankAccount: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		validationState: {
			get: function () {
				return this.isValid
			},
			set: function (value) {
				this.$emit('update:is-valid', value)
			}
		}
	},
	methods: {
		reset: function () {
			this.$refs.form.reset()
		}
	}
}
</script>
